import React from 'react';
import BreadCrumb from '../../components/BreadCrumb';
import './Find.scss';
// import { Link } from 'react-router-dom';
import StepOne from '../../components/FindSteps/StepOne'
const Find = () => {

    return (
        <div className="find">
            <div className="container">
                <BreadCrumb link="/find" linkName="Öz ətrini Tap" />
                <div className="findStepSection">
                    <StepOne />
                </div>
            </div>
        </div>
    )
}

export default Find
