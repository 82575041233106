import React, { useState, useEffect } from "react";
import "../Support.scss";
import BreadCrumb from "../../../components/BreadCrumb";
import SupportSidebar from "../../../components/SupportSidebar";
import Collapsible from "react-collapsible";
import { ReactComponent as Arrow } from "../../../assets/arrowPink.svg";
import ApiService from "../../../api/axios";
import Meta from "../../../components/Meta/Meta";
import { _t } from "../../../helpers/helpers";

const Faq = () => {
  const [pageMeta, setPageMeta] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [faqList, setFaqList] = useState([]);

  useEffect(() => {
    ApiService.get("faq").then((resp) => {
      setPageMeta(resp.data.page_meta);
      setBreadcrumbs(resp.data.page_meta.breadcrumbs);
      setFaqList(resp.data.data);
      console(resp.data.data);
    });
  }, []);

  return (
    <div className="support contact faq">
      <Meta meta={pageMeta} />
      <div className="container">
        <BreadCrumb breadcrumbs={breadcrumbs} />
        <h2 className="title">{_t("title.support")}</h2>
        <div className="row">
          <div className="xl-3 lg-3 md-3 sm-12 positionRelative">
            <SupportSidebar />
          </div>
          <div className="xl-9 lg-9 md-9 sm-12">
            <div className="supportMain">
              <div className="supportHeader">
                <h4>{pageMeta.title}</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: pageMeta.content,
                  }}
                ></div>
              </div>
              <div className="supportBody">
                <div className="supportBodyCenter">
                  <div className="supportFaq">
                    {faqList.map((faq) => {
                      return (
                        <Collapsible trigger={[faq.question, <Arrow />]}>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: faq.answer,
                            }}
                          ></p>
                        </Collapsible>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
