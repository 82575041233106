import React, { Component } from "react";
import "./Category.scss";
import CategoryImg from "../../../assets/category.png";
import CategoryImg2 from "../../../assets/category2.png";
import CategoryImg3 from "../../../assets/category3.png";
import CategoryImg4 from "../../../assets/category4.png";
import { Link } from "react-router-dom";
import ApiService from "../../../api/axios";
import { _t } from "../../../helpers/helpers";
class Category extends Component {
  constructor() {
    super();
    this.state = {
      genders: [],
    };
  }
  componentDidMount() {
    ApiService.get("helpers/genders").then((resp) => {
      // console.log(resp.data.data)
      this.setState({ genders: resp.data.data });
    });
  }
  render() {
    return (
      <div className="category-section">
        <div className="category-box">
          {this.state.genders.map((gender) => (
            <Link
              to={`/products?gender=${gender.key}`}
              className="category-box-child a"
              key={gender.id}
            >
              <img src={gender.image} alt="category" />
              <h4>{_t(gender.title)}</h4>
            </Link>
          ))}
        </div>
        <div className="more-category">
          <Link to="/products">{_t("buttons.all_parfum")}</Link>
        </div>
      </div>
    );
  }
}

export default Category;
