import React, { useEffect, useState } from "react";
import "./SpecialPerfume.scss";
import BreadCrumb from "../../components/BreadCrumb";
import CollectionImg from "../../assets/col.png";
import { Link } from "react-router-dom";
import ApiService from "../../api/axios";
import Meta from "../../components/Meta/Meta";
import { _t } from "../../helpers/helpers";

const Collection = () => {
  const [giftPackages, setGiftPackages] = useState({
    media: [],
  });
  const [pageMeta, setPageMeta] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    ApiService.get("/pageByUrl?url=special-perfumes").then((resp) => {
      setGiftPackages(resp.data.data);
      setPageMeta(resp.data.page_meta);
      setBreadcrumbs(resp.data.page_meta.breadcrumbs);
    });
  }, []);

  return (
    <div className="collection">
      <Meta meta={pageMeta} />
      <div className="container">
        <BreadCrumb breadcrumbs={breadcrumbs} />
        <div className="collectionHeader">
          <h4 className="title">{pageMeta.title}</h4>
          <p>{pageMeta.description}</p>
        </div>
      </div>
      <div className="container-second">
        {giftPackages.media.map((giftPackage) => {
          return (
            <div className="row">
              <div className="xl-6 lg-6 md-6 sm-12 collectionCard">
                <div className="collectionImgCard">
                  <div className="collectionImgCardChild">
                    <img src={giftPackage.slider} alt="collection" />
                  </div>
                </div>
              </div>
              <div className="xl-6 lg-6 md-6 sm-12 collectionCard">
                <div className="collectionTextParent">
                  <div className="collectionText">
                    <h6 className="title">{giftPackage.title}</h6>
                    <p
                    dangerouslySetInnerHTML={{
                      __html: giftPackage.content,
                    }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Collection;
