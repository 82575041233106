import React, { useState, useEffect } from "react";
import "../Support.scss";
import BreadCrumb from "../../../components/BreadCrumb";
import SupportSidebar from "../../../components/SupportSidebar";
import Payment1 from "../../../assets/payment1.png";
import Payment2 from "../../../assets/payment2.png";
import Payment3 from "../../../assets/payment3.png";
import Payment4 from "../../../assets/payment4.png";
import Payment5 from "../../../assets/payment5.png";
import Payment6 from "../../../assets/payment6.png";
import Payment7 from "../../../assets/payment7.png";
import Payment8 from "../../../assets/payment8.png";
import Meta from "../../../components/Meta/Meta";
import ApiService from "../../../api/axios";
import { _t } from "../../../helpers/helpers";

const Payment = () => {
  const [payment, setPayment] = useState("");
  const [pageMeta, setPageMeta] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    ApiService.get("pageByUrl?url=payment").then((resp) => {
      console.log(resp.data.data);

      setPayment(resp.data.data);
      setPageMeta(resp.data.page_meta);
      setBreadcrumbs(resp.data.page_meta.breadcrumbs);
    });
  }, []);

  return (
    <div className="support contact">
      <Meta meta={pageMeta} />
      <div className="container">
        <BreadCrumb breadcrumbs={breadcrumbs} />

        <h2 className="title">{_t("title.support")}</h2>
        <div className="row">
          <div className="xl-3 lg-3 md-3 sm-12 positionRelative">
            <SupportSidebar />
          </div>
          <div className="xl-9 lg-9 md-9 sm-12">
            <div className="supportMain">
              <div className="supportHeader">
                <h4>{payment.title}</h4>
              </div>
              <div className="supportBody">
                <div className="supportBodyCenter">
                  <div
                    className="supportContent payment"
                    dangerouslySetInnerHTML={{
                      __html: payment.content,
                    }}
                  ></div>
                  {/* <div className="paymentMethod">
                    <div className="paymentMethodCard">
                      <p>1. Terminalda "Bank xidmətləri" bölməsi seçilir</p>
                      <img src={Payment1} alt="paymentMethod" />
                    </div>
                    <div className="paymentMethodCard">
                      <p>2. "Kapital Bank" seçilir.</p>
                      <img src={Payment2} alt="paymentMethod" />
                    </div>
                    <div className="paymentMethodCard">
                      <p>3."Kart hesabının artırılması" bölməsi seçilir.</p>
                      <img src={Payment3} alt="paymentMethod" />
                    </div>
                    <div className="paymentMethodCard">
                      <p>
                        4. Kartın üzrəindəki 16 rəqəmli nömrə (4169733213329217)
                        daxil edilir və "İrəli" düyməsi sıxılır.
                      </p>
                      <img src={Payment4} alt="paymentMethod" />
                    </div>
                    <div className="paymentMethodCard">
                      <p>
                        5. Daxil olunan məlumat yoxlanılır və əgər doğrudursa
                        "Bəli" düyməsi sıxılır.
                      </p>
                      <img src={Payment5} alt="paymentMethod" />
                    </div>
                    <div className="paymentMethodCard">
                      <p>
                        6. Bu səhifədə kart sahibinin ad, soyad və ata adı
                        görünür. Məlumatlar doğrudursa "İrəli" düyməsi sıxılır.
                      </p>
                      <img src={Payment6} alt="paymentMethod" />
                    </div>
                    <div className="paymentMethodCard">
                      <p>
                        7. Məbləği daxil etmək üçün "İrəli" düyməsi sıxılır.
                      </p>
                      <img src={Payment7} alt="paymentMethod" />
                    </div>
                    <div className="paymentMethodCard">
                      <p>8. Məbləğ daxil edilir və "İrəli" düyməsi sıxılır.</p>
                      <img src={Payment8} alt="paymentMethod" />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
