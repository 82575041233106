import React, { useEffect, useState } from "react";
import AccountSidebar from "../../../components/AccountSidebar";
import BreadCrumb from "../../../components/BreadCrumb";
import { ReactComponent as Tick } from "../../../assets/tick.svg";
import LogOut from "../../../components/Logout/LogOut";
import Meta from "../../../components/Meta/Meta";
import { _t } from "../../../helpers/helpers";
import ApiService from "../../../api/axios";
import BonusImg from "../../../assets/bonus.png";
import BonusLogo from "../../../assets/bonusLogo.png";

const Bonus = (props) => {
  //   useEffect(() => {
  //     ApiService.get("/account").then((resp) => {
  //       if (resp.data.user.provider_id && resp.data.user.provider) {
  //         props.history.push("/");
  //       }
  //     });
  //   }, []);

  return (
    <div className="profile favoritesPage products">
      <div className="container">
        <Meta
          meta={{
            meta_title: "bonus",
          }}
        />
        <BreadCrumb
          breadcrumbs={[
            {
              title: "IYDƏ",
              url: "/",
            },
            {
              title: "bonus",
              url: "#",
            },
          ]}
        />{" "}
        <div className="account-header">
          <div className="title">
            <h5>{_t("pages.personal_info.title")}</h5>
            <p>{_t("account_content")}</p>
            <LogOut />
          </div>
        </div>
        <div className="row">
          <div className="xl-3 lg-3 md-3 sm-12 positionRelative">
            <AccountSidebar />
          </div>
          <div className="xl-9 lg-9 md-9 sm-12">
            <div className="account-fav">
              <h5 className="account-title"> {_t("pages.bonus.title")}</h5>
              <div className="row">
                <div className="xl-6 lg-6 md-6 sm-12">
                  <div className="bonusCard">
                    <img src={BonusImg} alt="bonus" />
                    <div className="bonusBottom">
                      <div>
                        <img src={BonusLogo} alt="logo" />
                        <p>
                          Bonuslarım: <span> 0</span>
                        </p>
                      </div>
                      <strong>000 0000 0000</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bonus;
