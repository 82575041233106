import React, { useEffect, useState } from "react";
import "./ChangePassword.scss";
import AccountSidebar from "../../../components/AccountSidebar";
import BreadCrumb from "../../../components/BreadCrumb";
import { ReactComponent as Tick } from "../../../assets/tick.svg";
import LogOut from "../../../components/Logout/LogOut";
import Meta from "../../../components/Meta/Meta";
import { _t } from "../../../helpers/helpers";
import ApiService from "../../../api/axios";
import Swal from "sweetalert2";

const ChangePassword = (props) => {
  const [old_password, setOld_password] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPassword_confirmation] = useState("");

  const handleChange = (e) => {
    e.preventDefault();
    ApiService.post("account/password", {
      old_password,
      password,
      password_confirmation,
    }).then((resp) => {
      setOld_password(resp.data.old_password);
      setPassword(resp.data.password);
      setPassword_confirmation(resp.data.password_confirmation);
      Swal.fire(_t("success.title"), _t("success.message"), "success");
    });
  };

  useEffect(() => {
    ApiService.get("/account").then((resp) => {
      if (resp.data.user.provider_id && resp.data.user.provider) {
        props.history.push("/");
      }
    });
  }, []);

  return (
    <div className="profile changePasswordPage">
      <div className="container">
        <Meta
          meta={{
            meta_title: "Change password",
          }}
        />
        <BreadCrumb
          breadcrumbs={[
            {
              title: "IYDƏ",
              url: "/",
            },
            {
              title: "Şifrəni dəyiş",
              url: "#",
            },
          ]}
        />
        <div className="account-header">
          <div className="title">
            <h5>{_t("pages.personal_info.title")}</h5>

            <p>{_t("account_content")}</p>
            <LogOut />
          </div>
        </div>
        <div className="row">
          <div className="xl-3 lg-3 md-3 sm-12 positionRelative">
            <AccountSidebar />
          </div>
          <div className="xl-9 lg-9 md-9 sm-12">
            <div className="account-changePassword">
              <div className="accountChange">
                <h5 className="account-title">
                  {_t("pages.change_password.title")}
                </h5>
                <form action="" className="accountForm" onSubmit={handleChange}>
                  <div className="input-group">
                    <div className="input-child">
                      <label htmlFor="">{_t("forms.current_password")}</label>
                      <input
                        type="password"
                        onChange={(e) => setOld_password(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="input-group">
                    <div className="input-child">
                      <label htmlFor="">{_t("forms.new_password")}</label>
                      <input
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="input-group">
                    <div className="input-child">
                      <label htmlFor="">{_t("forms.try_new_password")}</label>
                      <input
                        type="password"
                        onChange={(e) =>
                          setPassword_confirmation(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <button className="blackButton">
                    <Tick />
                    {_t("buttons.save")}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
