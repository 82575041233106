import React from "react";
import "./NewsCard.scss";
import { Link } from "react-router-dom";
import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
const NewsCard = ({ news }) => {
  return (
    <Link to={`/blog/${news.alias}`} className="newsCard">
      <div className="newsCardHeader">
        <LazyLoadImage
          src={news.title_image.slider}
          alt="news img"
          className="aboutImg"
          effect="blur"
        />
      </div>
      <div className="newsCardBody">
        <p>{news.title}</p>
      </div>
    </Link>
  );
};

export default trackWindowScroll(NewsCard);
