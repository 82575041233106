import React, { useState, useEffect } from "react";
import TopSlider from "../../components/HomePage/TopSlider";
import ContentCategory from "../../components/HomePage/ContentCategory";
import Category from "../../components/HomePage/Category";
import Collections from "../../components/HomePage/Collections";
import SelectedForYou from "../../components/HomePage/SelectedForYou";
import News from "../../components/HomePage/News";
import About from "../../components/HomePage/About";
import { useLocation } from "react-router-dom";
import { ReactComponent as CloseButton } from "../../assets/xB.svg";
import RegisterImg from "../../assets/registerBi.png";
import { Link } from "react-router-dom";
import { ReactComponent as User } from "../../assets/user.svg";
import Swal from "sweetalert2";
import { _t } from "../../helpers/helpers";
import ApiService from "../../api/axios";
const HomePage = () => {
  // let location = useLocation();
  // const [registerPopup, setRegisterPopup] = useState(true);
  // useEffect(() => {
  //     getUserAccount();
  // }, []);

  // const getUserAccount = () => {
  //     var token = location.search.split("?token_client_access=")[1];
  //     // setTimeout(() => document.body.classList.add("body-overflow"), 10000);
  //     // setTimeout(() => setRegisterPopup(!registerPopup), 10000);
  //     document.body.classList.add("body-overflow");

  //     if (token) {
  //         localStorage.setItem("token", token);
  //     }

  //     let popup_store = localStorage.getItem("popup_store");
  //     if (popup_store) {
  //         setRegisterPopup(false);
  //         document.body.classList.remove("body-overflow");
  //     }
  // };
  // const closeButton = () => {
  //     setRegisterPopup(false);

  //     localStorage.setItem("popup_store", true);
  //     document.body.classList.remove("body-overflow");
  // };

  return (
    <div className="homePage">
      {/* {registerPopup ? (
                <div className="popup registerPopup">
                    <div className="popupContainer">
                        <div className="popupInner">
                            <img src={RegisterImg} className='popupInnerBig' alt="register" />
                            <div className="popupInnerContent">
                                <div className="popupInnerContentChild">
                                    <h4>
                                        QEYDİYYATDAN KEÇ VƏ İSTƏDİYİN ƏTİRİ BİR TIKLA AL
                                    </h4>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor
                                    </p>
                                    <Link to="/register" className="blackButton" onClick={() => closeButton()}>
                                        <User /> QEYDİYYATDAN KEÇ
                                    </Link>
                                </div>
                            </div>
                            <button className="popupClose" onClick={() => closeButton()}>
                                <CloseButton />
                            </button>
                        </div>
                    </div>

                </div>
            ) : null} */}
      <TopSlider />
      <ContentCategory />
      <Category />
      <SelectedForYou />
      <Collections />
      <News />
      <About />
    </div>
  );
};

export default HomePage;
