import React, { useContext, useEffect, useState } from "react";
import "./TopSlider.scss";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import ApiService from "../../../api/axios";
import { _t } from "../../../helpers/helpers";
import { localeContext } from "../../Store";
import { Link } from "react-router-dom";

SwiperCore.use([Autoplay]);
const TopSlider = (props) => {
  const [slides, setSlides] = useState([]);
  const [locale, setLocale] = useContext(localeContext);

  useEffect(() => {
    ApiService.get("index/sliders").then((resp) => {
      setSlides(resp.data.data);
      console.log(resp.data.data);
    });
  }, [locale]);

  return (
    <div className="topSlider">
      <Swiper
        modules={[Pagination]}
        spaceBetween={1}
        slidesPerView={1}
        loop={true}
        pagination={{ clickable: true }}
        // autoplay={{
        //     delay: 5000,
        //     disableOnInteraction: false
        // }}
      >
        {slides.map((slide) => (
          <SwiperSlide
            style={{ background: `url(${slide.title_image.main})` }}
            key={slide.id}
            slide={slide}
          >
            <Link
              to={`${slide.link}`}
              className={slide.link == null ? "deactive" : ""}
            >
              <div className="container">
                <div className="row">
                  <div className="xl-7 lg-7 md-7 sm-12">
                    <div className="slider-content-center">
                      {slide.title == null ? null : <h4>{slide.title}</h4>}
                      {slide.description == null ? null : (
                        <p>{slide.description}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default TopSlider;
