import React, { useEffect, useState } from "react";
import "./About.scss";
import BreadCrumb from "../../components/BreadCrumb";
import AboutImg from "../../assets/news.png";
import { ReactComponent as Dot } from "../../assets/dot.svg";
import Certifcate from "../../assets/certifcate.png";
import ApiService from "../../api/axios";
import Meta from "../../components/Meta/Meta";
import { _t } from "../../helpers/helpers";

const About = () => {
  const [aboutList, setAboutList] = useState({
    title_image: {
      main: "",
    },
    page_blocks: [],
    media: [],
  });
  const [pageMeta, setPageMeta] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  //   const [page_blocks, setPageBlock] = useState([]);
  useEffect(() => {
    ApiService.get("pageByUrl?url=about").then((resp) => {
      console.log(resp);
      setAboutList(resp.data.data);
      setPageMeta(resp.data.page_meta);
      setBreadcrumbs(resp.data.page_meta.breadcrumbs);
      // setPageBlock(resp.data.data.page_blocks);
    });
  }, []);
  return (
    <div className="aboutPage">
      <Meta meta={pageMeta} />
      <div className="container">
        <BreadCrumb breadcrumbs={breadcrumbs} />
        <div className="row">
          <div className="xl-12 lg-12 md-12 sm-12">
            <div className="aboutPageContent">
              <h1>{aboutList.title}</h1>
              <h6 className="subtitle">{_t("title_who_are_we")}</h6>
              <p>{aboutList.description}</p>

              <img src={aboutList.title_image.main} alt="about img" />
            </div>
          </div>
          <div className="xl-1 lg-1 md-1 sm-12"></div>
          <div className="xl-10 lg-10 md-10 sm-12">
            <div className="about-card-section">
              {aboutList.page_blocks.map((item) => {
                return (
                  <div className="about-card" key={item}>
                    <h6>{item.title}</h6>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.content,
                      }}
                    ></div>
                  </div>
                );
              })}
            </div>
            <div className="about-card-section">
              {aboutList.page_blocks.map((items) => {
                return (
                  <div className="about-card-black" key={items}>
                    <Dot className="dot1" />
                    <h5>{items.title}</h5>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: items.content,
                      }}
                    ></div>
                    <Dot className="dot2" />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="xl-1 lg-1 md-1 sm-12"></div>
          <div className="xl-12 lg-12 md-12 sm-12">
            <h5 className="title">{_t("title_certificates")}</h5>
          </div>
          <div className="xl-12 lg-12 md-12 sm-12">
            <div className="row certifacate-row">
              {aboutList.media.map((item) => {
                return (
                  <div className="xl-4 lg-4 md-4 sm-12">
                    <div className="certifcate-card">
                      <img src={item.slider} alt="Certifcate" />
                      <div className="title">{item.title}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
