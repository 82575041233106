import React, { Component } from "react";
import "./ContentCategory.scss";
import { Link } from "react-router-dom";
import Category from "../../../assets/catergory.png";
import ApiService from "../../../api/axios";
import { _t } from "../../../helpers/helpers";

class ContentCategory extends Component {
  constructor() {
    super();
    this.state = {
      about: {
        title_image: {
          main: "",
          slider: "",
          thumbnail: "",
        },
      },
    };
  }

  componentDidMount() {
    ApiService.get("index/about").then((resp) => {
      this.setState({ about: resp.data.data });
    });
  }
  render() {
    return (
      <div className="contentCategory">
        <div className="container-second">
          <div className="row">
            <div className="xl-5 lg-5 md-5 sm-12">
              <div className="left">
                <h1>{this.state.about.title}</h1>
                <p>{this.state.about.description}</p>
                <div className="more-category">
                  <Link to={this.state.about.alias}>
                    {_t("buttons.more_detail")}
                  </Link>
                </div>
              </div>
            </div>
            <div className="xl-7 lg-7 md-7 sm-12 ContentCategoryImg">
              <img src={this.state.about.title_image.main} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContentCategory;
