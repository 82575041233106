import React, { useEffect, useState } from "react";
import "./SupportSidebar.scss";
import { NavLink } from "react-router-dom";
import ApiService from "../../api/axios";

const SupportSidebar = () => {
  const [supportLists, setSupportLists] = useState(null);

  useEffect(() => {
    ApiService.get("helpers/menu/header_menu").then((resp) => {
      setSupportLists(resp.data.data[resp.data.data.length - 1]?.sub_menus);
    });
  }, []);
  return (
    <div className="supportSidebar">
      {supportLists &&
        supportLists.length > 0 &&
        supportLists.map((item) => {
          return (
            <NavLink to={`/${item.path}`} activeClassName="red">
              {item.title}
            </NavLink>
          );
        })}
    </div>
  );
};

export default SupportSidebar;
