import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ApiService from "../../api/axios";
import BrandImg from "../../assets/logo.svg";
import { _t } from "../../helpers/helpers";

const Brand = () => {
  const [brands, setBrands] = useState({});

  useEffect(() => {
    ApiService.get("/brands").then((resp) => {
      setBrands(resp.data.data);
      console.log(resp.data.data);
    });
  }, []);

  return (
    <div className="brandsPage">
      <div className="container">
        <div className="brandsPageTop">
          <span>{_t("buttons.all.brand.parfum")}</span>
          <div>
            {Object.keys(brands).map((key, brand) => {
              return <a href={`#${key}`}>{key}</a>;
            })}
          </div>
        </div>
        {Object.keys(brands).map((key, brand) => {
          return (
            <div className="brandsDetail" id={key}>
              <div className="titleAlp">
                <p>{key}</p>
              </div>
              <div className="row">
                {brands[key].map((item) => {
                  return (
                    <div className="xl-3 lg-3 md-3 sm-12" key={item}>
                      <Link
                        to={`/products?brand=${item.alias}`}
                        className="brandCard"
                      >
                        <div className="imgCard">
                          <img src={item.title_image} alt="brand" />
                        </div>
                        <p>{item.title}</p>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Brand;
