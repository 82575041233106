import React, { useEffect } from "react";
import HeaderTop from "../HeaderTop";
import HeaderMiddle from "../HeaderMiddle";
import HeaderBottom from "../HeaderBottom";
// import HeaderTab from '../HeaderTab'
const Header = ({ settings }) => {
  return (
    <div className="header">
      <HeaderTop
        socials={{
          facebook: settings.facebook,
          instagram: settings.instagram,
          youtube: settings.youtube,
          tiktok: settings.tiktok,
        }}
      />
      <HeaderMiddle
        number={{ phone: settings.number, wp_link: settings.wp_link }}
        socials={{
          facebook: settings.facebook,
          instagram: settings.instagram,
          youtube: settings.youtube,
          tiktok: settings.tiktok,
        }}
      />
      <HeaderBottom />
      {/* <HeaderTab /> */}
    </div>
  );
};

export default Header;
